//utils
import { config } from './config'
import {
  logThis,
  hasClass,
  addRemoveClasses
} from './utils'

const handleMobileMenu = (pageWrapperId, openMobMenuId, closeMobMenuId, mobMenuId, iscriviMobId) => {
  if (config.debug) logThis("init mobile menu funcionality");
  const pageWrapperEl = document.getElementById(pageWrapperId);
  const openMobMenuEl = document.getElementById(openMobMenuId);
  const closeMobMenuEl = document.getElementById(closeMobMenuId);
  const iscriviMobEl = document.getElementById(iscriviMobId);
  const mobMenuEl = document.getElementById(mobMenuId);
  const openMobMenu = () => {
    /*
    Mobile menu, show / hide based on mobile menu state.
    Entering: "duration-200 ease-out"
    From: "opacity-0 scale-95"
    To: "opacity-100 scale-100"
    Leaving: "duration-100 ease-in"
    From: "opacity-100 scale-100"
    To: "opacity-0 scale-95"
   */
    const mobMenuHidden = hasClass(mobMenuEl, 'opacity-0');
    if (config.debug) logThis("mobile menu " + mobMenuId + " hidden: " + mobMenuHidden);
    if (mobMenuHidden) {
      addRemoveClasses(mobMenuEl, ['pointer-events-none'], 'remove');
      addRemoveClasses(mobMenuEl, ['transition'], 'add');
      addRemoveClasses(mobMenuEl, ['duration-100', 'ease-in', 'opacity-0', 'scale-95'], 'remove');
      addRemoveClasses(mobMenuEl, ['duration-200', 'ease-out', 'opacity-100', 'scale-100'], 'add');
    }
    //disable body scroll
    document.body.style.overflow = 'hidden';
    //half opacity to page wrapper
    pageWrapperEl.style.opacity = 0.5;    
  }
  const closeMobMenu = () => {
    /*
    Mobile menu, show / hide based on mobile menu state.
    Entering: "duration-200 ease-out"
    From: "opacity-0 scale-95"
    To: "opacity-100 scale-100"
    Leaving: "duration-100 ease-in"
    From: "opacity-100 scale-100"
    To: "opacity-0 scale-95"
   */
    const mobMenuHidden = hasClass(mobMenuEl, 'opacity-0');
    if (config.debug) logThis("mobile menu " + mobMenuId + " hidden: " + mobMenuHidden);
    if (!mobMenuHidden) {
      addRemoveClasses(mobMenuEl, ['duration-200', 'ease-out', 'opacity-100', 'scale-100'], 'remove');
      addRemoveClasses(mobMenuEl, ['duration-100', 'ease-in', 'opacity-0', 'scale-95'], 'add');
      addRemoveClasses(mobMenuEl, ['pointer-events-none'], 'add');
    }
    //enable body scroll
    document.body.style.overflow = 'auto';    
    //full opacity to page wrapper
    pageWrapperEl.style.opacity = 1;
  }
  const handleOnClickOpenMobMenuEl = () => {
    if (config.debug) logThis("clicked " + openMobMenuId);
    openMobMenu();
  }
  const handleOnClickCloseMobMenuEl = () => {
    if (config.debug) logThis("clicked " + closeMobMenuId);
    closeMobMenu();
  }
  const handleOnClickIscriviMobEl = () => {
    if (config.debug) logThis("clicked " + iscriviMobEl);
    closeMobMenu();
  }
  openMobMenuEl.addEventListener("click", handleOnClickOpenMobMenuEl);
  closeMobMenuEl.addEventListener("click", handleOnClickCloseMobMenuEl);
  iscriviMobEl.addEventListener("click", handleOnClickIscriviMobEl);
}
export default handleMobileMenu;