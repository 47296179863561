//utils
import { config } from './config'
import {
  logThis,
  addRemoveClasses
} from './utils'

const underLineMenuItem = (navItemClass, navItemMobClass) => {
  if (config.debug) logThis("start underline menu element");
  if (config.debug) console.log(window.location.pathname.split("/")[1]);
  if (config.debug) console.log(document.getElementsByClassName(navItemClass));
  if (config.debug) console.log(document.getElementsByClassName(navItemMobClass));
  const actualPathname = window.location.pathname.split("/")[1];
  const navItems = document.getElementsByClassName(navItemClass);
  const navItemsMob = document.getElementsByClassName(navItemMobClass);
  for (var i = 0; i < navItems.length; i++) {
    const navItem = navItems[i];
    const navItemHref = navItem.href.split("/");
    const navItemPathname = navItemHref[navItemHref.length - 1];
    if (config.debug) console.log(navItemPathname);
    if (actualPathname === navItemPathname) {
      addRemoveClasses(navItem, ['text-4i-violet'], 'remove');
      addRemoveClasses(navItem, ['text-4i-red'], 'add');
    } else {
      addRemoveClasses(navItem, ['text-4i-red'], 'remove');
      addRemoveClasses(navItem, ['text-4i-violet'], 'add');
    }
  }
  for (var i = 0; i < navItemsMob.length; i++) {
    const navItemMob = navItemsMob[i];
    const navItemMobHref = navItemMob.href.split("/");
    const navItemMobPathname = navItemMobHref[navItemMobHref.length - 1];
    if (actualPathname === navItemMobPathname) {
      console.log(navItemMob.childNodes[1]); //TOGLI
      addRemoveClasses(navItemMob.childNodes[1], ['text-4i-violet'], 'remove');
      addRemoveClasses(navItemMob.childNodes[1], ['text-4i-red'], 'add');
    } else {
      addRemoveClasses(navItemMob.childNodes[1], ['text-4i-red'], 'remove');
      addRemoveClasses(navItemMob.childNodes[1], ['text-4i-violet'], 'add');
    }
  }
}
export default underLineMenuItem;
