//style
import '../style/style.css';
import( /* webpackChunkName: "my-special-style" */ '../style/fonts.css');
//vanilla-lazyload
import LazyLoad from "vanilla-lazyload";
//config
import { config } from '../js/config'
//utils
import { addRemoveClasses, logThis } from '../js/utils'
//custom code
import underLineMenuItem from '../js/underLineMenuItem'
import handleMobileMenu from '../js/handleMobileMenu'
import handleFlyoutMenu from '../js/handleFlyoutMenu'
import headerLogoAnimation from '../js/headerLogoAnimation'


window.addEventListener('load', (event) => {
  if (config.debug) logThis('page is fully loaded');
  var lazyLoadInstance = new LazyLoad({
    // Your custom settings go here
  });
  underLineMenuItem("nav-item", "nav-item-mob");
  handleMobileMenu('page-wrapper', 'open-mob-menu', 'close-mob-menu', 'mob-menu', 'iscriviti-mob-nav');
  // headerAnimation('header', 'hide-trigger-header', 'show-trigger-header');
  // handleFlyoutMenu('open-docs-flyout-menu', 'docs-flyout-menu', 700);
  headerLogoAnimation('logo-container', 0.4, 0.05, 'static-header-logo');
  // const contactFormOpt = {
  //   baseBody: {
  //     // "Template": "newsletter.eml",
  //     "Email": ""
  //   },
  //   formId: "newsletter-form",
  //   formSendButtonId: "newsletter-form-send-button",
  //   formSpinnerId: "newsletter-form-spinner",
  //   formPolicyCheckId: "",
  //   formThanksMessageId: "newsletter-form-thanks-message",
  //   formErrorMessagesId: "newsletter-form-send-error-message",
  //   formFields: {
  //     Email: {
  //       id: "newsletter-email",
  //       errorId: "newsletter-email-error",
  //       type: "email",
  //       appendToBody: true,
  //       required: true,
  //       missingError: "This field is required",
  //       compilationError: "Insert a valid mail"
  //     },
  //     Address: {
  //       id: "newsletter-address",
  //       type: "text",
  //       required: false,
  //       trap: true,
  //     }
  //   },
  //   onSuccessScrollTop: false
  // }
  // const url = config.api.baseUrl + config.api.newsletter;
  // contactForm(contactFormOpt, url, "PUT");
  /*handle modale*/
  /*
  const modalePromoEl = document.getElementById("modale-promo");
  const modalePromoCloseEl = document.getElementById("close-modale-promo");
  const handleOnClickModalePromoEl = () => {
    if (config.debug) logThis("clicked " + modalePromoCloseEl);
    addRemoveClasses(modalePromoEl, ['hidden'], 'add');
  }
  modalePromoCloseEl.addEventListener("click", handleOnClickModalePromoEl);
  */
});
